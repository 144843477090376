<template>
  <div>
    <show-top-agent-self-renew-order v-if="this.$store.getters.userRole === 'top_agent'" />
    <show-agency-self-renew-order v-else-if="this.$store.getters.userRole === 'agency'" />
    <show-merchant-self-renew-order v-else-if="this.$store.getters.userRole === 'merchant'" />
  </div>
</template>

<script>

export default {
  name: 'ShowRenewOrder',
  components: {
    ShowTopAgentSelfRenewOrder: () => import('@/views/agent_monthly_bills_commission/renew/top_agent_self/Show'),
    ShowAgencySelfRenewOrder: () => import('@/views/agent_monthly_bills_commission/renew/agency_self/Show'),
    ShowMerchantSelfRenewOrder: () => import('@/views/agent_monthly_bills_commission/renew/agency_self/Show')
  }
}
</script>
